$(document).foundation();

$(document).ready(function () {
  // utils
  initVerticalCenter();
  initAnimateInView();
  inlineSVGs([
    ".header__burger img",
    ".footer__social img",
    ".location__locations img",
    ".off-canvas__close img",
    ".popup__close img",
  ]);

  // global
  initHeader();
  initOffCanvas();
  initGlovedHand();
  initSliders();
  initFirstWords();
  initButtons();
  initPopup();
  initCustomFilters();
  initForms();
  customCookieBar();
  initSelectric();
  initVideoPanel();

  // template specific
  initOtherRooms();
  initAccordions();
  initGallery();
  initAlignAccommodation();
  initRomeFeatures();
  setTimeout(function () {
    $(window).trigger("resize");
  }, 50);
});

function initVerticalCenter() {
  $(".vertical-center").each(function () {
    $(this).wrapInner(
      '<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>'
    );
  });
}

function initAnimateInView() {
  $(window).on("DOMContentLoaded load resize scroll", animateElementsInView);
  animateElementsInView();
}

function animateElementsInView() {
  $("[data-animate-in-view]").each(function () {
    //console.log($(this));
    if (isElementInViewport($(this))) {
      $(this).addClass($(this).data("animate-in-view"));
    } /* else if ( isElementCompletelyOutsideViewport($(this)) ) {
			$(this).removeClass( $(this).data('animate-in-view') );
		}*/
  });

  $("[data-animate-on-hover]").on("mouseenter", function () {
    $(this).addClass($(this).data("animate-on-hover"));

    $(this)
      .find("[data-animate-on-hover-child]")
      .each(function () {
        $(this).addClass($(this).data("animate-on-hover-child"));
      });
  });

  $("[data-animate-on-hover]").on("mouseleave", function () {
    $(this).removeClass($(this).data("animate-on-hover"));

    $(this)
      .find("[data-animate-on-hover-child]")
      .each(function () {
        $(this).removeClass($(this).data("animate-on-hover-child"));
      });
  });
}

function isElementInViewport(el) {
  var rect = el[0].getBoundingClientRect();
  var midPoint = rect.top + rect.height / 2;

  // Check that center of the element is between top and bottom of window before animating
  if (midPoint >= 0 && midPoint <= window.innerHeight) {
    return true;
  } else {
    return false;
  }
}

/*function isElementCompletelyOutsideViewport(el) {
	var rect = el[0].getBoundingClientRect();
	var midPoint = rect.top + (rect.height / 2);
	
	// Check that top and bottom of the element are either above or below viewport
	if (
		( (rect.top < 0) && (rect.bottom < 0) ) ||
		( (rect.top > window.innerHeight) && (rect.bottom > window.innerHeight) )
	) {
		return true;
	} else {
		return false;
	}
}*/

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
  var selectorString = "";
  if (typeof inputValue === "object") {
    selectorString = inputValue.join(",");
  } else {
    selectorString = inputValue;
  }

  $(selectorString).each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    if (imgURL.indexOf(".svg") > -1) {
      $.get(
        imgURL,
        function (data) {
          // Get the SVG tag, ignore the rest
          var $svg = $(data).find("svg");

          // Add replaced image's ID to the new SVG
          if (typeof imgID !== "undefined") {
            $svg = $svg.attr("id", imgID);
          }
          // Add replaced image's classes to the new SVG
          if (typeof imgClass !== "undefined") {
            $svg = $svg.attr("class", imgClass + " replaced-svg");
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr("xmlns:a");

          // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
          if (
            !$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
          ) {
            $svg.attr(
              "viewBox",
              "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
            );
          }

          // Replace image with new SVG
          $img.replaceWith($svg);
        },
        "xml"
      );
    }
  });
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function throttle(fn, threshhold, scope) {
  threshhold || (threshhold = 250);
  var last, deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date(),
      args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 * of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
	if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

	var viewportWidth, viewportHeight;
	if (scaleToParent) {
		viewportWidth = $(element).parent().innerWidth();
		viewportHeight = $(element).parent().innerHeight();
	} else {
		viewportWidth = $(window).innerWidth();
		viewportHeight = $(window).innerHeight();
	}

	var $element = $(element);

	var elementWidth = $element.attr('width');
	var elementHeight = $element.attr('height');

	// Aspect ratio = height / width
	var viewportRatio = viewportHeight / viewportWidth;
	var videoRatio = elementHeight / elementWidth;

	//console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


	var newWidth = (elementWidth / elementHeight) * viewportHeight
	var newHeight = (elementHeight / elementWidth) * viewportWidth;
	var newTop = 0;

	if (viewportRatio > videoRatio) {
		// set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
		//console.log('crop left and right');
		$element.css('height', viewportHeight);
		$element.css('width', newWidth);
		$element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
		$element.css('top', '0');
	} else if (viewportRatio < videoRatio) {
		// set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
		//console.log('crop top and bottom');
		$element.css('width', viewportWidth);
		$element.css('height', newHeight);
		$element.css('left', '0');
		$element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
	} else {
		// set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
		//console.log('no crop');
		$element.css('height', viewportHeight);
		$element.css('width', viewportWidth);
		$element.css('left', '0');
		$element.css('top', '0');
	}
}


function initHeader() {
  // Detect if the header is over and image slider, if yes then give it a transparent background
  setHeaderTransparency();
  $(window).on("scroll", setHeaderTransparency);

  function setHeaderTransparency() {
    if (window.pageYOffset < 150) {
      $(".header").removeClass("header--scrolled");
    } else {
      $(".header").addClass("header--scrolled");
      $("body").addClass("body--scrolled");
    }
  }

  $(".header__dropdown-menu button").on("click", function () {
    $(this).toggleClass("open");
    $(this).next("ul").slideToggle();
  });

  $(".header__dropdown-menu").on("mouseleave", function () {
    $(this).find("button").removeClass("open");
    $(this).find("ul").slideUp();
  });
}

function initOffCanvas() {
  //$('#offCanvas').foundation('open');

  // Turn both sides of the burger menu blue if there's no offer
  if ($("#offCanvas .off-canvas__offer-content").length === 0) {
    $("#offCanvas").addClass("no-offer");
  }

  $(".off-canvas__nav-primary > ul > li").each(function () {
    // Automatically add the "Overview" links to the landing page
    if ($(this).find("ul").length > 0) {
      var overviewLink = $(this).find("a").first().attr("href");
      $(this)
        .find("ul")
        .prepend('<li><a href="' + overviewLink + '">Overview</a></li>');
    }
  });

  $(".off-canvas__nav-primary > ul > li > a").on("click", function () {
    if ($(this).next(".off-canvas__nav-sub").length > 0) {
      // only trigger if there are sub pages to show
      if ($(this).parent().hasClass("open")) {
        // second click of main item closes sub pages
        $(this).parent().removeClass("open");
        $(this).next().slideUp();
      } else {
        // close any other open items
        $(".off-canvas__nav-primary > ul > li.open")
          .removeClass("open")
          .find(".off-canvas__nav-sub")
          .slideUp();

        // and open the new one
        $(this).parent().addClass("open");
        $(this).next().slideDown();
      }
      return false; // prevent navigating
    }
  });
}

// Set an HTML element as a cursor
// https://stackoverflow.com/questions/15314050/replace-cursor-with-an-html-element
// http://jsfiddle.net/howderek/mCgmP/
var ElementCursor = {
  cursorElement: "",
  setCursor: function (cursorId) {
    if (ElementCursor.cursorElement != "") {
      $("#" + ElementCursor.cursorElement).hide();
    }
    $("html").css({
      cursor: "none",
    });
    ElementCursor.cursorElement = cursorId;
    ElementCursor.updateCursor();
  },
  removeCursor: function () {
    $("html").css({
      cursor: "",
    });
    if (ElementCursor.cursorElement != "") {
      $("#" + ElementCursor.cursorElement).hide();
    }
    ElementCursor.cursorElement = "";
  },
  updateCursor: function () {
    $(document).mousemove(function (e) {
      if (ElementCursor.cursorElement != "") {
        $("#" + ElementCursor.cursorElement)
          .show()
          .css({
            position: "absolute",
            top: e.pageY + 2 + "px",
            left: e.pageX + 2 + "px",
          });
      }
    });
  },
};

function initGlovedHand() {
  if (!$("body").hasClass("theme-brussels")) return;

  $(".footer__newsletter-header h2").html(
    $(".footer__newsletter-header h2")
      .html()
      .replace(
        "zwanze",
        "<em><span>z</span><span>w</span><span>a</span><span>n</span><span>z</span><span>e</span></em>"
      )
  );

  $("body").append('<div id="cursor-gloved"></div>');

  $(
    '.footer__newsletter h2 em, input[type="submit"], button[type="submit"], .button, .header__right a, .header__right button'
  ).on("mouseenter", function () {
    ElementCursor.setCursor("cursor-gloved");
  });

  $(document).on("mouseout", function () {
    ElementCursor.removeCursor();
  });
}

function initSliders() {
  $(".slider__inner").slick({
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
  });

  if ($(".slider, .directions-map").length === 0) {
    pushContentBelowHeader();
    $(window).on("resize", pushContentBelowHeader);
  } else {
    $("body").addClass("has-slider");
  }

  $(".features__carousel-inner").slick({
    arrows: true,
    dots: true,
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    appendArrows: ".features__carousel .carousel-nav",
    appendDots: ".features__carousel .carousel-nav",
  });

  $(".image-feature__inner").slick({
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  });

  // Normal landing items have a carousel of images
  $(".landing__item").each(function () {
    $(this)
      .find(".landing__slider-inner")
      .slick({
        fade: true,
        arrows: true,
        dots: true,
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        appendArrows: $(this).find(".carousel-nav"),
        appendDots: $(this).find(".carousel-nav"),
      });
  });

  $(".group-location__item").each(function () {
    $(this)
      .find(".group-location__slider-inner")
      .slick({
        fade: true,
        arrows: true,
        dots: true,
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        appendArrows: $(this).find(".carousel-nav"),
        appendDots: $(this).find(".carousel-nav"),
      });
  });

  // The Landing Carousel variant is mostly based on the basic Landing template, but the whole item is now a carousel
  $(".landing--carousel").each(function () {
    $(this).slick({
      fade: true,
      arrows: false,
      dots: false,
    });

    $(this)
      .find(".carousel-nav")
      .append('<span class="slick-prev slick-arrow" style=""></span>')
      .append('<span class="slick-next slick-arrow" style=""></span>');

    // Manual nav required for each item
    var totalItems = $(this).find(".landing__item").length;
    var count = 0;
    $(this)
      .find(".landing__item")
      .each(function () {
        var dots = '<ul class="slick-dots">';
        for (var i = 0; i < totalItems; i++) {
          if (count === i) {
            dots +=
              '<li class="slick-active" role="presentation"><button type="button" role="tab" aria-selected="true">' +
              (count + 1 * 1) +
              "</button></li>";
          } else {
            dots +=
              '<li role="presentation"><button type="button" role="tab"">' +
              (count + 1 * 1) +
              "</button></li>";
          }
        }
        dots += "</ul>";
        $(this).find(".carousel-nav").append(dots);
        count++;
      });

    $(this)
      .find(".slick-prev")
      .on("click", function () {
        $(this).parents(".landing--carousel").slick("slickPrev");
      });

    $(this)
      .find(".slick-next")
      .on("click", function () {
        $(this).parents(".landing--carousel").slick("slickNext");
      });

    $(this)
      .find(".slick-dots button")
      .on("click", function () {
        $(this)
          .parents(".landing--carousel")
          .slick("slickGoTo", $(this).text());
      });
  });

  $(".accommodation__slider-inner, .rome-accommodation__slider-inner").slick({
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  });

  $(".featured-carousel").each(function () {
    $(this)
      .find(".featured-carousel__inner")
      .slick({
        slidesToShow: 3,
        arrows: true,
        dots: true,
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        appendArrows: $(this).find(".carousel-nav"),
        appendDots: $(this).find(".carousel-nav"),
        responsive: [
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
  });

  $(".rome-carousel").each(function () {
    $(this)
      .find(".rome-carousel__inner")
      .slick({
        slidesToShow: 3,
        arrows: true,
        dots: true,
        centerMode: true,
        centerPadding: 0,
        prevArrow: '<span class="slick-prev"></span>',
        nextArrow: '<span class="slick-next"></span>',
        appendArrows: $(this).find(".carousel-nav"),
        appendDots: $(this).find(".carousel-nav"),
        responsive: [
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });

    $(this)
      .find(".rome-carousel__prev")
      .on("click", function () {
        $(this).siblings(".rome-carousel__inner").slick("slickPrev");
      });

    $(this)
      .find(".rome-carousel__next")
      .on("click", function () {
        $(this).siblings(".rome-carousel__inner").slick("slickNext");
      });
  });

  $(".meetings-landing__item-slider-inner").slick({
    fade: true,
    arrows: true,
    dots: true,
  });
}

function pushContentBelowHeader() {
  $("main").css("padding-top", $(".header").outerHeight() + "px");
}

function initFirstWords() {
  if (!$("body").hasClass("theme-brussels")) return;

  $(
    ".intro h1, .intro h2, .image-feature__content h2, .location__pattern-inner h2, .landing__content-inner h2 a"
  ).each(function () {
    // Wrap a span around the first word so we can style it
    var wordz = $(this).text().split(" ");

    var splitWordz = "<span>" + wordz[0] + "</span>";

    if (wordz.length > 1) {
      for (i = 1; i < wordz.length; i++) {
        splitWordz += " " + wordz[i];
      }
    }

    $(this).html(splitWordz);
  });
}

function initButtons() {
  if (!$("body").hasClass("theme-brussels")) return;

  $(".button").each(function () {
    $(this).append("<span>" + $(this).text() + "</span>");
  });
}

function initOtherRooms() {
  $(".other-rooms__carousel").slick({
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          arrows: true,
          dots: true,
          prevArrow: '<span class="slick-prev"></span>',
          nextArrow: '<span class="slick-next"></span>',
          appendArrows: ".other-rooms .carousel-nav",
          appendDots: ".other-rooms .carousel-nav",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          arrows: true,
          dots: true,
          prevArrow: '<span class="slick-prev"></span>',
          nextArrow: '<span class="slick-next"></span>',
          appendArrows: ".other-rooms .carousel-nav",
          appendDots: ".other-rooms .carousel-nav",
        },
      },
    ],
  });
}

function initAccordions() {
  $(".accordion__inner").accordiom({
    showFirstItem: false,
  });
}

function clickCloseButtons() {
  var closeButtons = document.querySelectorAll('.popup__close');

  closeButtons.forEach(function(button) {
    button.click();
  });
}

function initSelectric() {
  $(function() {
    $('select').selectric();
  });
}

function initPopup() {
  if ($("#popup").length > 0) {
    var popupDelay = $("#popup").data("popup-delay") || 0;
    var popupKeepHiddenFor = $("#popup").data("popup-keep-hidden-for-n-days") || 0;
    var closeAfterDelay = $("#popup").data("popup-close-after") || 0;

    // Only show the popup if the cookie has not been seen, or always show it for testing in either local or remote markup
    if (
      !Cookies.get("hasSeenPopup") ||
      location.hostname.indexOf("localhost") > -1 ||
      location.hostname.indexOf("projects") > -1
    ) {
      setTimeout(function () {
        $("#popup").foundation("open");

        if (popupKeepHiddenFor > 0) {
          Cookies.set("hasSeenPopup", "true", { expires: popupKeepHiddenFor });
        }
      }, popupDelay);

      // After the last setTimeout has fired, wait for the specified time in closeAfterDelay and then call clickCloseButtons
      setTimeout(function () {
        clickCloseButtons();
      }, popupDelay + closeAfterDelay);
    }
  }
}

function initCustomFilters() {
  $(".filter select").selectric();

  if (
    $("body").hasClass("template-offers") ||
    $("body").hasClass("template-blog-landing")
  ) {
    $(".filter__select select").on("change", function () {
      var buttonText = $(".filter__select select").val();

      $(".filter__buttons a.button").each(function () {
        if ($(this).find("span").text() === buttonText) {
          window.location = $(this).attr("href");
        }
      });
    });
  }
}

function initGallery() {
  $(".gallery__item a").on("click", function () {
    var $clickedThumbnail = $(this);

    // Dynamically load the gallery slider into a reveal popup
    var modal =
      '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-image"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="21.92" height="21.921" viewBox="0 0 21.92 21.921"> <g id="Component_5_118" data-name="Component 5 – 118" transform="translate(0.354 0.354)"><line id="Line_1" data-name="Line 1" x2="30" transform="translate(0 21.214) rotate(-45)" fill="none" stroke="#fff" stroke-width="1"/><line id="Line_2" data-name="Line 2" x2="30" transform="rotate(45)" fill="none" stroke="#fff" stroke-width="1"/></g></svg></button><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';

    modal = modal.replace("{{src}}", $(this).attr("href"));
    modal = modal.replace("{{caption}}", $(this).data("title") || "");

    var cta = "";

    if ($(this).data("description") !== "") {
      cta = "<p>" + $(this).data("description") + "</p>";
    }

    if (
      typeof $(this).data("book-link") !== "undefined" &&
      $(this).data("book-link") !== ""
    ) {
      cta +=
        '<a href="' +
        $(this).data("book-link") +
        '" class="button button--primary" target="_blank">' +
        ($(this).data("book-title") || "Book Now") +
        "</a>";
    }
    modal = modal.replace("{{cta}}", cta);

    var $modal = modal;

    $("body").append($modal);

    $("#gallery__modal")
      .foundation()
      .on("closed.zf.reveal", function () {
        $("#gallery__modal").remove();
      });

    $("#gallery__modal").foundation("open");

    $(".gallery__modal-next").on("click", function () {
      $("#gallery__modal").foundation("close");
      $("#gallery__modal").remove();
      if ($clickedThumbnail.parent().next(":visible").length > 0) {
        // is the next thumb visible?
        $clickedThumbnail.parent().next(":visible").find("a").trigger("click");
      } else if ($clickedThumbnail.parent().nextUntil(":visible").length > 0) {
        // no, so find the next visible one
        $clickedThumbnail
          .parent()
          .nextAll(":visible")
          .first()
          .find("a")
          .trigger("click");
        $("#gallery__modal").foundation("close");
        $("#gallery__modal").remove();
      }
    });

    $(".gallery__modal-prev").on("click", function () {
      $("#gallery__modal").foundation("close");
      $("#gallery__modal").remove();
      if ($clickedThumbnail.parent().prev(":visible").length > 0) {
        // is the previous thumb visible?
        $clickedThumbnail.parent().prev(":visible").find("a").trigger("click");
      } else if ($clickedThumbnail.parent().prevUntil(":visible").length > 0) {
        // no, so find the next visible one that comes before this
        $clickedThumbnail
          .parent()
          .prevAll(":visible")
          .last()
          .find("a")
          .trigger("click");
        $("#gallery__modal").foundation("close");
        $("#gallery__modal").remove();
      }
    });

    return false;
  });

  refreshMasonry();
  $(".gallery").on("filter-changed", function () {
    setTimeout(refreshMasonry, 600);
  });

  Foundation.onImagesLoaded($(".gallery__item img"), refreshMasonry);

  function refreshMasonry() {
    $(".gallery__grid").masonry({
      columnWidth: ".gallery__item-sizer",
      itemSelector: ".gallery__item",
      percentPosition: true,
      horizontalOrder: true,
    });
  }

  // Remove the transition class after loading to avoid jumpy thumbnails
  setTimeout(function () {
    $(".gallery__item").removeClass("transition--2000");
  }, 2000);

  // Remove empty filter buttons (if not handled by the plugin)
  $(".filter__buttons button").each(function () {
    if ($(this).text() == "") {
      $(this).remove();
    }
  });
}

function initAlignAccommodation() {
  if ($(".accommodation").length > 0) {
    alignAccommodation();
    $(window).on("resize", alignAccommodation());
  }
}

function alignAccommodation() {
  if ($(window).innerWidth() > 640) {
    $(".accommodation__item:nth-child(odd)").each(function () {
      var contentHeight = $(this).find(".accommodation__content").outerHeight();
      $(this).css("padding-bottom", contentHeight / 2 + "px");
    });

    $(".accommodation__item:nth-child(even)").each(function () {
      var contentHeight = $(this).find(".accommodation__content").outerHeight();
      $(this).css("padding-top", contentHeight / 2 + "px");
    });
  } else {
    $(".accommodation__item").attr("style", "");
  }
}

function initForms() {
  $(".form__select select").selectric();

  $('.form__file-upload input[type="file"]').on("change", function () {
    refreshFileList($(this));
  });
}

function refreshFileList(input) {
  var attachedFiles = input[0].files;

  if (typeof attachedFiles !== "undefined") {
    $(".form__file-upload").find("p").remove();

    if (attachedFiles.length > 0) {
      $(".form__file-upload").append("<p>Attached:</p>");
    }

    for (var i = 0; i < attachedFiles.length; i++) {
      $(".form__file-upload").append(
        "<p>" +
          attachedFiles[i].name +
          ' <button class="clear" data-index="' +
          i +
          '">Clear</button></p>'
      );
    }

    $(".clear").on("click", function (e) {
      removeFileFromFileList(
        $(this).parent("p").siblings("input"),
        $(this).data("index")
      );
      refreshFileList($(this).parent("p").siblings("input"));
    });
  }
}

// https://stackoverflow.com/questions/3144419/how-do-i-remove-a-file-from-the-filelist
function removeFileFromFileList(input, index) {
  var dt = new DataTransfer();
  var input = $(input)[0];
  var files = input.files;

  for (var i = 0; i < files.length; i++) {
    var file = files[i];
    if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
  }

  input.files = dt.files; // Assign the updates list
}

function customCookieBar() {
  if ($(".cookie-bar").length > 0) {
    if (!Cookies.get("cookies_accepted")) {
      $(".cookie-bar").fadeIn();
    }

    $(".cookie-bar__accept").on("click", function () {
      Cookies.set("cookies_accepted", "true", { expires: 30 });
      $(".cookie-bar").fadeOut();
    });
  }
}

function initRomeFeatures() {
  $(".rome-features__content li").on("mouseenter", function () {
    $(
      '.rome-features__image-hover[data-item="' + $(this).data("image") + '"]'
    ).addClass("visible");
  });

  $(".rome-features__content li").on("mouseleave", function () {
    $(".rome-features__image-hover").removeClass("visible");
  });
}

function initVideoPanel() {
  coverViewport($('.video-panel__media iframe'), true);
  $(window).on('resize', function () {
    coverViewport($('.video-panel__media iframe'), true);
  });
}
